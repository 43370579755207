.cursor-pointer {
  cursor: pointer;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cdk-drag {
  &:not(.cdk-drag-dragging) .cdk-drag-handle {
    cursor: grab;
  }

  &.cdk-drag-dragging .cdk-drag-handle {
    cursor: grabbing;
  }
}

[data-scale-wrapper] svg {
  display: block;
}
