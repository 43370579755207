html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Titillium Web', sans-serif;
  height: 100vh; /* Standard height */
  height: 100dvh; /* Dynamic height to fix iOS bar hiding the UI */
  overflow: hidden;
}
