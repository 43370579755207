html {
  scroll-behavior: smooth;
}

body {
  font-family: "Titillium Web", sans-serif;
  height: 100vh; /* Standard height */
  height: 100dvh; /* Dynamic height to fix iOS bar hiding the UI */
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cdk-drag:not(.cdk-drag-dragging) .cdk-drag-handle {
  cursor: grab;
}
.cdk-drag.cdk-drag-dragging .cdk-drag-handle {
  cursor: grabbing;
}

[data-scale-wrapper] svg {
  display: block;
}